import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./memberList.css";
import * as actions from "../redux/MemberAction";
import { callAgentList } from "../../agentlist/redux/AgentAction";
import { useParams } from "react-router-dom";
import { Switch } from "antd";
import { EditMemberDialog } from "./EditMemberDialog";
import { Modal } from "../../common/Modal1";

export const MemberList = ({
  selMemberId,
  currentAgentId,
  currentMemberPage,
  isMemberDetailsVisible,
  memberSettingPermission,
  onClose,
  isAgent,
}) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const memberState = useSelector(({ member }) => member, shallowEqual);

  useEffect(() => {
    if (selMemberId) {
      const queryParams = {
        action: "memberdetail",
        memberid: selMemberId,
      };
      dispatch(actions.callMemberList(queryParams));
    }
  }, [selMemberId]);

  useEffect(() => {
    if (memberState && memberState.memberLists) {
      setPassword(memberState.memberLists.password);
      setNickname(memberState.memberLists.nickname);
    }
  }, [memberState]);

  const handleSwitchChange = (checked, member) => {
    const updateQueryParams = {
      action: "updatememberstatus",
      memberid: member,
    };
    dispatch(actions.updateMemberStatus(updateQueryParams))
      .then(() => {
        const queryParams = {
          action: "memberdetail",
          memberid: selMemberId,
        };
        dispatch(actions.callMemberList(queryParams));
      })
      .catch((error) => {
        console.error("Error updating Member status:", error);
      });
  };
  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleClose = () => {
    onClose();
    const queryParams = {
      action: "agentmemberlist",
      selagentid: currentAgentId,
      agentpagenum: "0",
      memberpagenum: currentMemberPage - 1,
    };
    dispatch(callAgentList(queryParams));
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="memberDetailsModal"
        title={intl.formatMessage({ id: "memberDetails" })}
        dismissBtnRef={dismissBtnRef}
        isVisible={isMemberDetailsVisible}
        onClose={handleClose}
      >
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <EditMemberDialog
          setSwalProps={setSwalProps}
          memberId={selMemberId}
          nickname={nickname}
          password={password}
          isVisible={isEditModalVisible}
          isAgent={isAgent}
          onClose={() => setEditModalVisible(false)}
        />

        <div>
          <div
            className="d-flex"
            style={{
              overflowX: "auto",
              margin: "10px auto",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <div className="member-container">
              <div className="member-header">
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "username" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "nickname" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "password" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "ipaddress" })}
                </div>
                <div className="member-cell text-start ">
                  {intl.formatMessage({ id: "inviteby" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "status" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "regdate" })}
                </div>
              </div>
              <div className="member-middle">
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
              </div>
              <div className="member-body1">
                {memberState && memberState.memberLists ? (
                  <div>
                    <div className="member-cell text-start">
                      {memberState.memberLists.username}
                    </div>
                    {editButton === true ? (
                      <>
                        <div className="member-cell text-start">
                          <input
                            type="text"
                            style={{
                              borderColor: "rgba(175, 164, 164, 0.4)",
                              borderRadius: "5px",
                            }}
                            placeholder={memberState.memberLists.nickname}
                            onChange={handleNicknameChange}
                          />
                        </div>
                        <div className="member-cell text-start">
                          <input
                            type="text"
                            style={{
                              borderColor: "rgba(175, 164, 164, 0.4)",
                              borderRadius: "5px",
                            }}
                            placeholder={memberState.memberLists.password}
                            onChange={handlePasswordChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^a-zA-Z0-9]/g,
                                ""
                              );
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="member-cell text-start">
                          {memberState.memberLists.nickname}
                        </div>
                        <div className="member-cell text-start">
                          {memberState.memberLists.password}
                        </div>
                      </>
                    )}
                    <div className="member-cell text-start">
                      {memberState.memberLists.ipaddress === ""
                        ? "-"
                        : memberState.memberLists.ipaddress}
                    </div>
                    <div className="member-cell text-start">
                      {memberState.memberLists.inviteby}
                    </div>
                    {memberSettingPermission === "true" ? (
                      <>
                        <div className="member-cell text-start">
                          <Switch
                            checked={
                              memberState.memberLists.status === "active"
                            }
                            onChange={(checked) =>
                              handleSwitchChange(
                                checked,
                                memberState.memberLists.memberid
                              )
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="member-cell text-start">
                          {memberState.memberLists.status}
                        </div>
                      </>
                    )}
                    <div className="member-cell text-start">
                      {memberState.memberLists.regdate}
                    </div>
                  </div>
                ) : (
                  <Skeleton height="600px" className="w-100" />
                )}
              </div>
            </div>
          </div>
          {memberSettingPermission === "true" &&
            (editButton === true ? (
              <div className="p-3 d-flex justify-content-evenly">
                <button
                  className="btn btn-success px-3"
                  onClick={() => {
                    setEditModalVisible(true);
                  }}
                >
                  {intl.formatMessage({ id: "submit" })}
                </button>
                <button
                  className="btn btn-danger px-3"
                  onClick={() => {
                    setEditButton(false);
                  }}
                >
                  {intl.formatMessage({ id: "cancel" })}
                </button>
              </div>
            ) : (
              <div className="editButton p-3">
                <button
                  className="btn btn-primary px-3"
                  onClick={() => {
                    setEditButton(true);
                  }}
                >
                  {intl.formatMessage({ id: "edit" })}
                </button>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};
