import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DateRangeComp.css";
import { useIntl } from "react-intl";
import { Color } from "antd/es/color-picker";

const DateRangeComp = ({ initialRange, onDateChange }) => {
  const intl = useIntl();
  const [range, setRange] = useState(initialRange);
  const [selectedRange, setSelectedRange] = useState(initialRange);
  const [open, setOpen] = useState(false);
  const [direction, setDirection] = useState("horizontal");
  const refOne = useRef(null);

  useEffect(() => {
    const updateDirection = () => {
      if (window.innerWidth <= 768) {
        setDirection("vertical");
      } else {
        setDirection("horizontal");
      }
    };
    window.addEventListener("resize", updateDirection);
    updateDirection();

    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);

    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
      window.removeEventListener("resize", updateDirection);
    };
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (
      refOne.current &&
      !refOne.current.contains(e.target) &&
      !e.target.classList.contains("inputBox")
    ) {
      setOpen(false);
    }
  };

  const handleRangeChange = (item) => {
    setRange([item.selection]);
  };

  useEffect(() => {
    if (!open) {
      setRange(selectedRange);
    }
  }, [open, selectedRange]);

  return (
    <div className="calendarWrap">
      <input
        value={`${format(selectedRange[0].startDate, "yyyy-MM-dd")} to ${format(
          selectedRange[0].endDate,
          "yyyy-MM-dd"
        )}`}
        readOnly
        className="inputBox"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      />
      <div ref={refOne}>
        {open && (
          <div
            className="position-absolute w-100"
            style={{
              maxWidth: "350px",
              zIndex: "99999",
              backgroundColor: "var(--table-background)",
            }}
          >
            <div
              className="position-relative"
              style={{ paddingBottom: "2.2rem" }}
            >
              <DateRange
                preventSnapRefocus={true}
                showSelectionPreview={true}
                onChange={handleRangeChange}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={range}
                direction={direction}
                className="calendarElement"
                maxDate={new Date()}
                calendarFocus="backwards"
              />
              <div className="d-flex align-items-center justify-content-end position-absolute end-0 bottom-0 pb-2 pe-3">
                <div className="me-4 bg-muted" onClick={() => setOpen(false)}>
                  {intl.formatMessage({ id: "cancel" })}
                </div>
                <button
                  className="btn btn-primary px-2 py-1 fw-bold"
                  onClick={() => {
                    setOpen(false);
                    setSelectedRange(range);
                    onDateChange(range[0].startDate, range[0].endDate);
                  }}
                >
                  {intl.formatMessage({ id: "confirm" })}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangeComp;
