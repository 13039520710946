import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/AgentAction";
import { Modal } from "../../common/Modal1";
import { useAuth } from "../../auth/components/AuthInit";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { useNavigate } from "react-router-dom";

export const AgentLotteryList = ({
  pathfromSearch,
  currentAgentId,
  searchName,
  selAgentId,
  selUsername,
  isAgentLotteryVisible,
  onClose,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [swalProps, setSwalProps] = useState({});
  const dismissBtnRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [editButton, setEditButton] = useState(false);
  const navigate = useNavigate();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    if (selAgentId) {
      const queryParams = {
        action: "agent4dlist",
        selagentid: selAgentId,
      };
      dispatch(actions.callAgent4DList(queryParams)).then((resp) => {
        resp = resp.data;
        if (resp.vbossusername == "") {
          setEditButton(true);
        } else {
          setEditButton(false);
          setPassword(resp.vbosspassword);
          setUsername(resp.vbossusername);
        }
      });
    }
  }, [selAgentId, isAgentLotteryVisible]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleEditClick = () => {
    setEditButton(true);
  };
  const handleOnClose = () => {
    onClose();
    setEditButton(false);
  };
  const handleSubmitClick = () => {
    const queryParams = {
      action: "setupagent4d",
      selagentid: selAgentId,
      vbossusername: username,
      vbosspassword: password,
    };
    dispatch(actions.callAgent4DList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "submitDetailsSuccess" }),
          onConfirm: () => {
            const queryParams = {
              action: "agent4dlist",
              selagentid: selAgentId,
            };
            dispatch(actions.callAgent4DList(queryParams)).then((resp) => {
              resp = resp.data;
              setEditButton(false);
            });
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  const handleConnectClick = () => {
    const queryParams = {
      action: "vbossconnecttest",
      selagentid: selAgentId,
    };
    dispatch(actions.agent4DConnectList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "connectSuccess" }),
          onConfirm: () => {
            const queryParams = {
              action: "agent4dlist",
              selagentid: selAgentId,
            };
            dispatch(actions.callAgent4DList(queryParams)).then((resp) => {
              resp = resp.data;
              setEditButton(false);
            });
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="Agent4DModal"
        title={intl.formatMessage({ id: "vBossDetails" })}
        subtitle={selUsername}
        dismissBtnRef={dismissBtnRef}
        isVisible={isAgentLotteryVisible}
        onClose={handleOnClose}
      >
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="member-container">
          <div className="member-header">
            <div className="member-cell text-start">
              {intl.formatMessage({ id: "username" })}
            </div>
            <div className="member-cell text-start">
              {intl.formatMessage({ id: "password" })}
            </div>
            <div className="member-cell text-start">
              {intl.formatMessage({ id: "connection" })}
            </div>
          </div>
          <div className="member-middle">
            <div className="member-cell">:</div>
            <div className="member-cell">:</div>
            <div className="member-cell">:</div>
          </div>
          {editButton === true ? (
            <div className="member-body1">
              {agentState && agentState.agent4DLists ? (
                <div>
                  <div className="member-cell text-start">
                    <input
                      type="text"
                      style={{
                        borderColor: "rgba(175, 164, 164, 0.4)",
                        borderRadius: "5px",
                      }}
                      placeholder={agentState.agent4DLists.vbossusername}
                      onChange={handleUsernameChange}
                    />
                  </div>
                  <div className="member-cell text-start">
                    <input
                      type="text"
                      style={{
                        borderColor: "rgba(175, 164, 164, 0.4)",
                        borderRadius: "5px",
                      }}
                      placeholder={agentState.agent4DLists.vbosspassword}
                      onChange={handlePasswordChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z0-9]/g,
                          ""
                        );
                      }}
                    />
                  </div>
                  <div
                    className="member-cell text-start"
                    style={{
                      color:
                        agentState.agent4DLists.isconnect === "true"
                          ? "green"
                          : "red",
                    }}
                  >
                    {intl.formatMessage({
                      id:
                        agentState.agent4DLists.isconnect === "true"
                          ? "connected"
                          : "notConnected",
                    })}
                  </div>
                </div>
              ) : (
                <Skeleton height="600px" className="w-100" />
              )}
            </div>
          ) : agentState && agentState.agent4DLists ? (
            <div>
              <div className="member-cell text-start">
                {agentState.agent4DLists.vbossusername}
                {agentState.agent4DLists.isconnect === "true" &&
                  editButton === false && (
                    <i
                      className="bi bi-check-circle-fill"
                      style={{ color: "green" }}
                    />
                  )}
              </div>
              <div className="member-cell text-start">
                {agentState.agent4DLists.vbosspassword}
              </div>
              <div
                className="member-cell text-start"
                style={{
                  color:
                    agentState.agent4DLists.isconnect === "true"
                      ? "green"
                      : "red",
                }}
              >
                {intl.formatMessage({
                  id:
                    agentState.agent4DLists.isconnect === "true"
                      ? "connected"
                      : "notConnected",
                })}
              </div>
            </div>
          ) : (
            <Skeleton height="600px" className="w-100" />
          )}
        </div>
        {agentState.agent4DLists &&
        agentState.agent4DLists.vbossusername === "" ? (
          <div className="editButton p-3">
            <button
              className="btn btn-primary px-3"
              onClick={handleSubmitClick}
            >
              {intl.formatMessage({ id: "submit" })}
            </button>
          </div>
        ) : editButton === true ? (
          <div
            className="editButton p-3"
            style={{ justifyContent: "space-evenly" }}
          >
            <button
              className="btn btn-primary px-3"
              onClick={handleSubmitClick}
            >
              {intl.formatMessage({ id: "submit" })}
            </button>
            {/* <button
              className="btn btn-primary px-3"
              onClick={handleConnectClick}
            >
              {intl.formatMessage({ id: "connect" })}
            </button> */}
          </div>
        ) : (
          <div
            className="editButton p-3"
            style={{ justifyContent: "space-evenly" }}
          >
            <button className="btn btn-primary px-3" onClick={handleEditClick}>
              {intl.formatMessage({ id: "edit" })}
            </button>
            <button
              className="btn btn-success px-3"
              onClick={handleConnectClick}
            >
              {intl.formatMessage({ id: "connect" })}
            </button>
            <button
              className="btn btn-primary px-3"
              onClick={() => {
                navigate("/accountList/lotterySetup", {
                  state: {
                    selAgentId: selAgentId,
                    currentAgentId: currentAgentId,
                  },
                });
              }}
            >
              {intl.formatMessage({ id: "setupPrize" })}
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};
