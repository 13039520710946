import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/ReportAction";
import { useNavigate } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useAuth } from "../../auth/components/AuthInit";
import "../../memberlist/components/MemberReport.css";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const TransactionReportList = () => {
  const intl = useIntl();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  // const [fromDate, setFromDate] = useState(subDays(new Date(), 30));
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [currentAgent, setCurrentAgent] = useState(auth.username);
  const reportState = useSelector(({ report }) => report, shallowEqual);
  const timeZone = "Asia/Kuala_Lumpur";
  useEffect(() => {
    const formattedFromDate = formatInTimeZone(
      fromDate,
      timeZone,
      "yyyy-MM-dd"
    );
    const formattedToDate = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
    const queryParams = {
      action: "agentreportV2",
      fromdate: formattedFromDate,
      todate: formattedToDate,
    };
    dispatch(actions.transactionReport(queryParams));
  }, [dispatch]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchClick = () => {
    const formattedStart = formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd");
    const formattedEnd = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
    const queryParams = {
      action: "agentreportV2",
      fromdate: formattedStart,
      todate: formattedEnd,
    };
    dispatch(actions.transactionReport(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  //   const handleClick = (agentId, username) => {
  //     setCurrentAgent(username);
  //     const formattedFromDate = formatInTimeZone(
  //       fromDate,
  //       timeZone,
  //       "yyyy-MM-dd"
  //     );
  //     const formattedToDate = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
  //     const queryParams = {
  //       action: "allagentfcgamereport",
  //       fromdate: formattedFromDate,
  //       todate: formattedToDate,
  //       selagentid: agentId,
  //     };
  //     dispatch(actions.agentDownlineReport(queryParams));
  //     // .then((resp) => {
  //     //   resp = resp.data;
  //     //   setTopUpLine(resp.agentUplineLists);
  //     // })
  //     // .catch((error) => {
  //     //   console.error("Error fetching agent list:", error);
  //     // });
  //   };

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "transactionReport" })}</h2>
      </div>
      <div
        style={{
          margin: "5px 0",
          textDecoration: "underline",
        }}
      >
        <span>{currentAgent}</span>
      </div>
      <div className="dateSearch-div">
        <div className="dateRange-div">
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleSearchClick()}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>

      <div>
        {reportState && reportState.transactionReportLists && (
          <div
            className={`rounded card-css text-start my-2 py-2 mobile-overflow `}
            style={{
              overflowX: "auto",
              border: "1px solid grey",
            }}
          >
            <table
              className="card-css "
              style={{ width: "100%", minWidth: "800px" }}
            >
              <thead>
                <tr
                  style={{
                    borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                  }}
                >
                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "totalTopup" }) +
                      " : " +
                      reportState.transactionReportLists.totaltopup}
                  </td>
                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "manual" }) +
                      " : " +
                      reportState.transactionReportLists.manualtopup}
                  </td>
                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "gateway" }) +
                      " : " +
                      reportState.transactionReportLists.paymentgatewaytopup}
                  </td>

                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "refundTopup" }) +
                      " : " +
                      reportState.transactionReportLists.refundtopup}
                  </td>
                </tr>
                <tr
                  style={{
                    borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                  }}
                >
                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "totalWithdraw" }) +
                      " : " +
                      reportState.transactionReportLists.totalwithdraw}
                  </td>
                  <td className="col-2 td-line"></td>
                  <td className="col-2 td-line"></td>
                </tr>
                <tr
                  style={{
                    borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                  }}
                >
                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "transferToDownline" }) +
                      " : " +
                      reportState.transactionReportLists.transfertodownline}
                  </td>
                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "withdrawDownline" }) +
                      " : " +
                      reportState.transactionReportLists.withdrawdownline}
                  </td>
                  <td className="col-2 td-line"></td>
                </tr>
                <tr>
                  <td className="col-2 td-line">
                    {intl.formatMessage({ id: "receiveFromUpline" }) +
                      " : " +
                      reportState.transactionReportLists.receivefromupline}
                  </td>
                  <td className="col-2  td-line">
                    {intl.formatMessage({ id: "withdrawByUpline" }) +
                      " : " +
                      reportState.transactionReportLists.withdrawbyupline}
                  </td>
                  <td className="col-2 td-line"></td>
                </tr>
              </thead>
            </table>
          </div>
        )}
      </div>

      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "date" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "balance" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "afBalance" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "action" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {reportState.transactionReportLists &&
                reportState.transactionReportLists.agentReportsV2 &&
                reportState.transactionReportLists.agentReportsV2.length > 0 ? (
                  reportState.transactionReportLists.agentReportsV2.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2">
                          {report.reportdate}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.balance}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.afbalance}
                        </td>
                        <td className="member-cell col-3 text-start">
                          {report.message}
                          {/* <button
                            className="btn btn-primary"
                            onClick={() =>
                              navigate(
                                `/agentReport/agentDownlineReport/${report.agentid}`,
                                {
                                  state: {
                                    fromDate: fromDate.toISOString(),
                                    toDate: toDate.toISOString(),
                                  },
                                }
                              )
                            }
                          >
                            {intl.formatMessage({ id: "details" })}
                          </button> */}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* <tfoot>
                {reportState && reportState.transactionReportLists && (
                  <tr className="member-row1">
                    <td className="member-cell hide-on-mobile col-1"></td>
                    <td
                      className="member-cell col-3"
                      style={{ textAlign: "end" }}
                    >
                      {intl.formatMessage({ id: "total" }) + " : "}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.transactionReportLists.totalbet}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.transactionReportLists.totalwin}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.transactionReportLists.totalprofit}
                    </td>
                  </tr>
                )}
              </tfoot> */}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
