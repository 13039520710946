import { useIntl } from "react-intl";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/AgentAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { CreateAgentForm } from "./CreateAgentForm";
import { Modal } from "../../common/Modal";
import { useAuth } from "../../auth/components/AuthInit";

export const CreateAgentDialog = ({
  setSwalProps,
  pageParams = { agentpagenum: "0", memberpagenum: "0" },
}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const { auth, saveAuth } = useAuth();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  const saveValues = (queryParams) => {
    dispatch(actions.createAgentList(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "createAgentSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            const queryParams = {
              action: "agentmemberlist",
              selagentid: auth.agentid,
              ...pageParams,
            };
            dispatch(actions.callAgentList(queryParams));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="addAgentModal"
        title={intl.formatMessage({ id: "createAgent" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 custom-border-bottom">
          <CreateAgentForm formikRef={formikRef} saveValues={saveValues} />
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          {agentState.actionsLoading && (
            <div
              className="spinner-border me-2"
              role="status"
              style={{ scale: "0.55" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <button
            type="button"
            disabled={agentState.actionsLoading}
            className="btn btn-primary px-3"
            onClick={() => formikRef.current.submitForm()}
          >
            {intl.formatMessage({ id: "create" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
