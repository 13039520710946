import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./agentList.css";
import * as actions from "../redux/AgentAction";
import { Switch } from "antd";
import { EditAgentDialog } from "./EditAgentDialog";
import { Modal } from "../../common/Modal1";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { AgentDetailsNavTitle } from "../../../constants/NavBarConst";
import { EditAgentAccDialog } from "./EditAgentAccDialog";

export const AgentDetails = ({
  selAgentId,
  currentAgentId,
  currentAgentPage,
  isAgentDetailsVisible,
  agentSettingPermission,
  onClose,
}) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [selectedNav, setSelectedNav] = useState(AgentDetailsNavTitle.INFO);
  const [selectedAccType, setSelectedAccType] = useState("");
  const [selectedDayCount, setSelectedDayCount] = useState("");
  const [isChecked, setIsChecked] = useState("");
  const [selectedAccConnect, setSelectedAccConnect] = useState("");
  const [editAccButton, setEditAccButton] = useState(false);
  const [isEditAccModalVisible, setEditAccModalVisible] = useState(false);
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    if (selAgentId) {
      const queryParams = {
        action: "agentdetail",
        selagentid: selAgentId,
      };
      dispatch(actions.callAgentDetails(queryParams));
    }
  }, [selAgentId]);

  useEffect(() => {
    if (agentState && agentState.callAgentDetails) {
      setPassword(agentState.callAgentDetails.password);
      setNickname(agentState.callAgentDetails.nickname);
    }
    if (agentState?.callAgentDetails?.acctisconnect) {
      const isConnected = agentState.callAgentDetails.acctisconnect === "true";
      setIsChecked(isConnected);
      setSelectedAccConnect(agentState.callAgentDetails.acctisconnect);
    }
    if (agentState?.callAgentDetails?.acctTypes?.length > 0) {
      setSelectedAccType(agentState.callAgentDetails.acctTypes[0].acctype);
    }
    if (agentState?.callAgentDetails?.acctDays?.length > 0) {
      setSelectedDayCount(agentState.callAgentDetails.acctDays[0].acctdaycount);
    }
  }, [agentState]);

  const handleSwitchChange = (checked, agent) => {
    const updateQueryParams = {
      action: "updateagentstatus",
      selagentid: agent,
    };
    dispatch(actions.updateAgentStatus(updateQueryParams))
      .then(() => {
        const queryParams = {
          action: "agentdetail",
          selagentid: selAgentId,
        };
        dispatch(actions.callAgentDetails(queryParams));
      })
      .catch((error) => {
        console.error("Error updating Agent status:", error);
      });
  };
  const handleCreateDownlineChange = (checked, agent) => {
    const updateQueryParams = {
      action: "updateagentcreatedownline",
      selagentid: agent,
    };
    dispatch(actions.updateAgentCreateDownline(updateQueryParams))
      .then((resp) => {
        resp = resp.data;
        if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
          setErrorMessage(resp.message);
          setErrorMessageVisible(true);
        }
        const queryParams = {
          action: "agentdetail",
          selagentid: selAgentId,
        };
        dispatch(actions.callAgentDetails(queryParams));
      })
      .catch((error) => {
        console.error("Error updating Agent status:", error);
      });
  };
  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleClose = () => {
    onClose();
    const queryParams = {
      action: "agentmemberlist",
      selagentid: currentAgentId,
      agentpagenum: currentAgentPage - 1,
      memberpagenum: "0",
    };
    dispatch(actions.callAgentList(queryParams));
  };
  const handleAccTypeChange = (e) => {
    setSelectedAccType(e.target.value);
  };
  const handleDayCountChange = (e) => {
    setSelectedDayCount(e.target.value);
  };
  const handleChange = (event) => {
    setIsChecked(event);
    setSelectedAccConnect(event);
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="agentDetailsModal"
        title={intl.formatMessage({ id: "agentDetails" })}
        dismissBtnRef={dismissBtnRef}
        isVisible={isAgentDetailsVisible}
        onClose={handleClose}
      >
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <EditAgentDialog
          setSwalProps={setSwalProps}
          agentId={selAgentId}
          nickname={nickname}
          password={password}
          isVisible={isEditModalVisible}
          onClose={() => {
            setEditModalVisible(false);
            setEditButton(false);
          }}
        />
        <EditAgentAccDialog
          setSwalProps={setSwalProps}
          agentId={selAgentId}
          accttype={selectedAccType}
          acctdaycount={selectedDayCount}
          acctisconnect={selectedAccConnect}
          isVisible={isEditAccModalVisible}
          onClose={() => {
            setEditAccModalVisible(false);
            setEditAccButton(false);
          }}
        />
        <div className="d-flex">
          {Object.entries(AgentDetailsNavTitle).map(([key, value]) => (
            <h6
              style={{
                color: selectedNav === value ? "#0080FF" : "",
                borderBottom: selectedNav === value ? "2px solid #0080FF" : "",
                padding: "10px",
                cursor: "pointer",
              }}
              className="text-font3"
              onClick={() => setSelectedNav(value)}
              key={key}
            >
              {intl.formatMessage({ id: value })}
            </h6>
          ))}
        </div>
        {selectedNav === "info" ? (
          <div className="agentDetails-div">
            <div className="p-2">
              {agentState.callAgentDetails ? (
                <table className="w-100 agentContainer-table">
                  <tr>
                    <td width="200px">
                      {intl.formatMessage({ id: "username" })}
                    </td>
                    <td>:</td>
                    <td>{agentState.callAgentDetails.username}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "nickname" })}</td>
                    <td>:</td>
                    <td>
                      {editButton === true ? (
                        <input
                          type="text"
                          style={{
                            borderColor: "rgba(175, 164, 164, 0.4)",
                            borderRadius: "5px",
                          }}
                          placeholder={agentState.callAgentDetails.nickname}
                          onChange={handleNicknameChange}
                        />
                      ) : (
                        agentState.callAgentDetails.nickname
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "password" })}</td>
                    <td>:</td>
                    <td>
                      {editButton === true ? (
                        <input
                          type="text"
                          style={{
                            borderColor: "rgba(175, 164, 164, 0.4)",
                            borderRadius: "5px",
                          }}
                          placeholder={agentState.callAgentDetails.password}
                          onChange={handlePasswordChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            );
                          }}
                        />
                      ) : (
                        agentState.callAgentDetails.password
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "status" })}</td>
                    <td>:</td>
                    <td>
                      {agentSettingPermission === "true" ? (
                        <Switch
                          checked={
                            agentState.callAgentDetails.status === "active"
                          }
                          onChange={(checked) =>
                            handleSwitchChange(
                              checked,
                              agentState.callAgentDetails.agentid
                            )
                          }
                        />
                      ) : (
                        agentState.callAgentDetails.status
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({ id: "crtDownline" })}{" "}
                      {errorMessageVisible === true && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontStyle: "italic",
                          }}
                        >
                          {"*" + errorMessage}
                        </span>
                      )}
                    </td>
                    <td>:</td>
                    <td>
                      <Switch
                        checked={
                          agentState.callAgentDetails.crtdownline === "true"
                        }
                        onChange={(checked) =>
                          handleCreateDownlineChange(
                            checked,
                            agentState.callAgentDetails.agentid
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "balance" })}</td>
                    <td>:</td>
                    <td> {agentState.callAgentDetails.balance}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "totalAgent" })}</td>
                    <td>:</td>
                    <td> {agentState.callAgentDetails.totalagentdownline}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: "totalMember" })}</td>
                    <td>:</td>
                    <td> {agentState.callAgentDetails.totalmember}</td>
                  </tr>
                </table>
              ) : (
                <Skeleton height="600px" className="w-100" />
              )}
            </div>
            {agentSettingPermission === "true" &&
              (editButton === true ? (
                <div className="p-3 d-flex justify-content-evenly">
                  <button
                    className="btn btn-success px-3"
                    onClick={() => {
                      setEditModalVisible(true);
                    }}
                  >
                    {intl.formatMessage({ id: "submit" })}
                  </button>
                  <button
                    className="btn btn-danger px-3"
                    onClick={() => {
                      setEditButton(false);
                    }}
                  >
                    {intl.formatMessage({ id: "cancel" })}
                  </button>
                </div>
              ) : (
                <div className="editButton p-3">
                  <button
                    className="btn btn-primary px-3"
                    onClick={() => {
                      setEditButton(true);
                    }}
                  >
                    {intl.formatMessage({ id: "edit" })}
                  </button>
                </div>
              ))}
          </div>
        ) : (
          <div className="agentDetails-div">
            <div
              className="d-flex"
              style={{
                overflowX: "auto",
                margin: "10px auto",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              {agentState && agentState.callAgentDetails ? (
                <div className="d-flex">
                  <table className="mx-2">
                    <tr>
                      <td>{intl.formatMessage({ id: "acctConnect" })}</td>
                    </tr>
                    <tr>
                      <td>{intl.formatMessage({ id: "acctType" })}</td>
                    </tr>
                    <tr>
                      <td>{intl.formatMessage({ id: "acctDayCount" })}</td>
                    </tr>
                    <tr>
                      <td>{intl.formatMessage({ id: "acctLastDate" })}</td>
                    </tr>
                  </table>
                  <table className="mx-2">
                    <tr>
                      <td>:</td>
                    </tr>
                    <tr>
                      <td>:</td>
                    </tr>
                    <tr>
                      <td>:</td>
                    </tr>
                    <tr>
                      <td>:</td>
                    </tr>
                  </table>
                  <table className="mx-2">
                    <tr>
                      <td>
                        {editAccButton === true ? (
                          <Switch checked={isChecked} onChange={handleChange} />
                        ) : (
                          <div
                            style={{
                              color:
                                agentState.callAgentDetails.acctisconnect ===
                                "true"
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {intl.formatMessage({
                              id:
                                agentState.callAgentDetails.acctisconnect ===
                                "true"
                                  ? "connected"
                                  : "notConnected",
                            })}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {editAccButton === true ? (
                          <select
                            className="dropdown"
                            onChange={handleAccTypeChange}
                          >
                            {agentState.callAgentDetails.acctTypes.map(
                              (type) => (
                                <option key={type.acctype} value={type.acctype}>
                                  {type.name}
                                </option>
                              )
                            )}
                          </select>
                        ) : agentState.callAgentDetails.accttype === "" ? (
                          "-"
                        ) : (
                          agentState.callAgentDetails.accttype
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {editAccButton === true ? (
                          <select
                            className="dropdown"
                            onChange={handleDayCountChange}
                          >
                            {agentState.callAgentDetails.acctDays.map((day) => (
                              <option key={day.daily} value={day.daily}>
                                {day.name}
                              </option>
                            ))}
                          </select>
                        ) : agentState.callAgentDetails.acctdaycount === "" ? (
                          "-"
                        ) : (
                          agentState.callAgentDetails.acctdaycount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {agentState.callAgentDetails.acctlastdate === ""
                          ? "-"
                          : agentState.callAgentDetails.acctlastdate}
                      </td>
                    </tr>
                  </table>
                </div>
              ) : (
                <Skeleton height="600px" className="w-100" />
              )}
              {/* <div className="agentDetails-container">
                <div className="agentDetails-header">
                  <div className="agentDetails-cell"></div>
                  <div className="agentDetails-cell">
                    {intl.formatMessage({ id: "acctType" })}
                  </div>
                  <div className="agentDetails-cell">
                    {intl.formatMessage({ id: "acctDayCount" })}
                  </div>
                </div>
                <div className="agentDetails-middle">
                  <div className="agentDetails-cell">:</div>
                  <div className="agentDetails-cell">:</div>
                  <div className="agentDetails-cell">:</div>
                </div>
                <div className="agentDetails-body">
                  {agentState && agentState.callAgentDetails ? (
                    <div className="agentDetails-row">
                      <div className="agentDetails-cell">
                        <Switch checked={isChecked} onChange={handleChange} />
                      </div>
                      {editButton === true ? (
                        <>
                          <div className="agentDetails-cell">
                            <select
                              className="dropdown"
                              onChange={handleAccTypeChange}
                            >
                              {agentState.callAgentDetails.acctTypes.map(
                                (type) => (
                                  <option
                                    key={type.acctype}
                                    value={type.acctype}
                                  >
                                    {type.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className="agentDetails-cell">
                            <select
                              className="dropdown"
                              onChange={handleDayCountChange}
                            >
                              {agentState.callAgentDetails.acctDays.map(
                                (day) => (
                                  <option key={day.daily} value={day.daily}>
                                    {day.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="agentDetails-cell">
                            {agentState.callAgentDetails.accttype}
                          </div>
                          <div className="agentDetails-cell">
                            {agentState.callAgentDetails.acctdaycount}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <Skeleton height="600px" className="w-100" />
                  )}
                </div>
              </div> */}
            </div>
            {editAccButton === true ? (
              <div className="p-3 d-flex justify-content-evenly">
                <button
                  className="btn btn-success px-3"
                  onClick={() => {
                    setEditAccModalVisible(true);
                  }}
                >
                  {intl.formatMessage({ id: "submit" })}
                </button>
                <button
                  className="btn btn-danger px-3"
                  onClick={() => {
                    setEditAccButton(false);
                  }}
                >
                  {intl.formatMessage({ id: "cancel" })}
                </button>
              </div>
            ) : (
              <div className="editButton p-3">
                <button
                  className="btn btn-primary px-3"
                  onClick={() => {
                    setEditAccButton(true);
                  }}
                >
                  {intl.formatMessage({ id: "edit" })}
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};
